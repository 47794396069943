
const themes = {
  colors: {
    white: '#ffffff',
    black: '#000000',
    gray: '#eeeeee',
    primary: '#ff002c',
    grayDark: '#2C2C2C',
  },
  font: {
    londrina: {
      bold: "LondrinaSolidBlack",
      light: "LondrinaSolidLight",
      regular: "LondrinaSolidRegular",
    },
    size: {
      xsmall: 9,
      msmall: 10,
      small: 12,
      normal: 14,
      neutral: 16,
      medium: 18,
      lmedium: 20,
      large: 24,
      mlarge: 28,
      nlarge: 30,
      hlarge: 48,
      xlarge: 64,
      xlargebig: 96,
    },
  },
};

export default themes;
