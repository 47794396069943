import {combineReducers} from 'redux';
import {configureStore} from '@reduxjs/toolkit';
import {langSlice} from '../slices/lang';
import {initLoadSlice} from '../slices/initLoad';
import {changeModeDark} from '../slices/modeDark';
import {changeTheme} from '../slices/theme';

const reducer = combineReducers({
  lang: langSlice.reducer,
  initLoad: initLoadSlice.reducer,
  modeDark: changeModeDark.reducer,
  theme: changeTheme.reducer,
});

export const store = configureStore({reducer: reducer});
