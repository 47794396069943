import {createSlice} from '@reduxjs/toolkit';
import {
  IAuthState,
  theme,
  initialState,
} from './types';

export const changeTheme = createSlice({
  name: 'theme',
  initialState: initialState,
  reducers: {},

  extraReducers: builder => {
    builder.addCase(theme, (state, action) => {
      console.log('initLoad:changetheme');
      state.theme = action.payload;
    });

  },
});
