export const styles = {
    container: {
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'black',

    },
    logo: {
      width: 400,
      height: 400,
      objectFit: 'contain', 
    },
    ButtonContent: {
      width: '100%',
      alignSelf: 'center',
      textAlign: 'center',
    },
    Button: {
      backgroundColor: 'white',
      borderRadius: 5,
      textAlign: 'center',
      padding: 10,
    },
    textBack: {
      color: 'black',
      alignSelf: 'center',
    },
  };
  