const translate = (lang, dataLang, name) => {
  let translated = name;

  if (lang === 'es' && dataLang) {
    const dataSpanish = dataLang.find((item) => item.language === 'Spanish');
    if (dataSpanish) {
      translated = dataSpanish.name;
    }
  }

  return translated;
};

export default translate;
