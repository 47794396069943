export const stylesInput = (modeDark) => {
  return {
    container: {
      marginVertical: 10,
    },
    input: {
      width: 400,
      height: 40,
      borderColor: 'gray',
      borderWidth: 1,
      paddingHorizontal: 10,
      color: modeDark === 'dark' ? 'white' : 'black',
    },
  };
};
