import React from 'react';
import './App.css';
import RootNavigator from './navigation/root';
import {Provider} from 'react-redux';
import {store} from './redux/store';
import './i18n';
function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <RootNavigator  />
      </Provider>
    </div>
  );
}

export default App;
