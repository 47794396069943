import {createSlice} from '@reduxjs/toolkit';
import {
  typeLanguage,
  initialState,
} from './types';

export const langSlice = createSlice({
  name: 'lang',
  initialState: initialState,
  reducers: {},

  extraReducers: builder => {
    builder.addCase(typeLanguage, (state, action) => {
      console.log('langSlice:typeLanguage');
      state.language = action.payload;
    });
  },
});
