const translateMenu = (
    lang,
    dataLang,
    name,
    field
  ) => {
  let translate = name;
  if(lang === 'es'){
    const dataSpanish = dataLang.find((item)=> item.language === 'Spanish');
    if (dataSpanish) {
        switch (field) {
          case 'regular_decription':
            translate = dataSpanish.regular_decription;
            break;
          case 'about':
            translate = dataSpanish.about;
            break;
          case 'region':
            translate = dataSpanish.region;
            break;
          default:
            translate = dataSpanish.name;
            break;
        }
      }
  }
  return translate.replace(/\\n/g,'\n');
};

export default translateMenu;