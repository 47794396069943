import React from 'react';

const Margin = ({ top, right, bottom, left }) => (
  <div
    style={{
      marginTop: top,
      marginRight: right,
      marginBottom: bottom,
      marginLeft: left,
    }}
  />
);

export default React.memo(Margin);
