import React from 'react';

import {useSelector} from 'react-redux';
//import Theme1 from './home/theme1';
import Theme2 from './home/theme2';
//import Theme3 from './home/theme3';

function Index() {
    const {theme} = useSelector((state) => state.theme);
    
    return (
        <>
            {/*theme === 'theme1' && <Theme1 />*/}
            {theme === 'theme2' && <Theme2 />}
            {/*theme === 'theme3' && <Theme3 />*/}
        </>
    );
}

export default Index;