import React from 'react';
import {useSelector} from 'react-redux';
//import Theme1 from './theme1';
import Theme2 from './theme2';
//import Theme3 from './theme3';

function Index() {
    const {theme} = useSelector((state) => state.theme);
   
    return (
        <>
            {/*theme === 'theme1' && <Theme1 />*/}
            {theme === 'theme2' && <Theme2 />}
            {/*theme === 'theme3' && <Theme3 />*/}
        </>
    );
}

export default Index;