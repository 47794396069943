import React from 'react';
import {commonStyles as stylesCommon} from '../../../styles/common';
import { Link, useNavigate } from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {stylesLayout} from './styles';


const Head = ({title, backButton = false}) => {
    const {t} = useTranslation();
    const {theme} = useSelector((state) => state.theme);
    const modeDark = useSelector((state) => state.modeDark).modeDark;
    const styles   = stylesLayout(modeDark); 
    const commonStyles  = stylesCommon(modeDark); 
    const navigate = useNavigate();

    return <div style={styles.content}>
                <p style={{...commonStyles.txt_h1_bold, ...{textAlign: 'center', fontSize: 30,margin: "10px 10px 10px 20px",}}}>{title}</p>
                {backButton && 
                    <div style={styles.backButtonContent}>
                        <Link style={theme === 'theme3' ? styles.backButtonRed: styles.backButton} 
                            onPress={() => navigate(-1)}
                        >
                            <p style={[commonStyles.txt_neutral, theme === 'theme3' ? styles.pBackRed: styles.pBack]}>
                            {t('back')}
                            </p>
                        </Link>
                    </div>
                 }
            </div>
};

export default React.memo(Head);
