import themes from '../../../themes';

export const stylesLayout = (modeDark) => {
    return {
        content: {
            zIndex: -1,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        btn: {
            marginTop: 30,
            marginLeft: 30
        },
        backText: {
            color: 'white',
            fontWeight: 'bold',
            fontSize: 30,
        },
        backButtonContent: {
            width: 161,
            textAlign: 'center',
        },
        backButton: {
            borderRadius: 5,
            textAlign: 'center',
            padding: 10,
            paddingVertical: 15,
            backgroundColor: modeDark === 'dark' ? themes.colors.white : themes.colors.black,
        },
        backButtonRed: {
            borderRadius: 5,
            textAlign: 'center',
            padding: 10,
            paddingVertical: 15,
            backgroundColor: themes.colors.primary,
        },
        textBack: {
            color:  modeDark === 'dark' ? themes.colors.black : themes.colors.white,
            alignSelf: 'center',
        },
        textBackRed: {
            color:  themes.colors.white,
            alignSelf: 'center',
            fontSize: 20
        },
        
    };
};




