import {createSlice} from '@reduxjs/toolkit';
import {
  modeDark,
  initialState,
} from './types';

export const changeModeDark = createSlice({
  name: 'modeDark',
  initialState: initialState,
  reducers: {},

  extraReducers: builder => {
    builder.addCase(modeDark, (state, action) => {
      console.log('initLoad:changeModeDark');
      state.modeDark = action.payload;
    });

  },
});
