import React from 'react';
import { useState } from 'react'; 
import { stylesInput } from './styles'
import Margin from '../../shared/margin';
import {commonStyles} from '../../../styles/common';

const CustomTextInput = ({
  placeholder,
  value,
  label,
  onChangeText,
  inputType = 'default',
  secureTextEntry = false,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const styles = stylesInput('light');

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <div style={styles.container}>
      {label && <div style={commonStyles.txt_h2}>{label}</div>}
      <Margin bottom={10} />
      <input
        style={styles.input}
        placeholder={placeholder}
        value={value}
        type={inputType === 'email' ? 'email' : 'text'}
        onChange={(e) => onChangeText(e.target.value)}
        onFocus={handleFocus}
        onBlur={handleBlur}
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="none"
        spellCheck="false"
        // Agregar más atributos según sea necesario
      />
    </div>
  );
};

export default CustomTextInput;
