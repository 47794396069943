import themes from '../../../../themes';
export const styles = {
    itemcategorie: {
        
        borderRadius: 5,
        textDecoration: "none",
       
         
        
    },
    contentItemsCategories: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: '#00000052',
    },
    itemcategorieDark: {
       
    },
    itemcategorieLight: {
        
    },
    icon: {
       width: 40,
       height: 40,
       resizeMode: 'contain'
    },
   
    label: {
        fontSize: 16,
        textAlign: 'center',
        color: 'white',
        textTransform: 'uppercase',
    },

    dividerImage: {
        marginTop: 15,
        width: '100%',
        height: 2,
    }

};
