import React, { useState, useEffect } from 'react';
//import { useHistory } from 'react-router-dom';

import CustomTextInput from '../../../components/forms/input';
//import { authLogin } from '@app/hooks/auth/';
import {commonStyles as stylesGeneral} from '../../../styles/common';
import { styles } from './styles';
import Margin from '../../../components/shared/margin';

function Index() {
  //const history = useHistory();

  const [email, setEmail] = useState('app@saboresdecolombia.com');
  const [password, setPassword] = useState('Pa$$w0rd!');
  const [loading, setLoading] = useState(false);

  const commonStyles = stylesGeneral('dark');

  const handleEmailChange = (text) => {
    setEmail(text);
  };

  const handlePasswordChange = (text) => {
    setPassword(text);
  };


  const Submit = async () => {
    if (loading) return false;

    if (email === '') {
      alert('Email is required');
    }

    if (password === '') {
      alert('Password is required');
    }

    console.log('login');

    // Lógica equivalente a obtener el FCM Token en React Native
    const fcmToken = 'dummyFCMToken';

    setLoading(true);

    const data = {
      email,
      password,
      fcmToken,
    };
/*
    // Lógica equivalente a enviar la solicitud de autenticación en React Native
    authLogin(data)
      .then(() => {
        setLoading(false);
        history.push('/home'); // Redireccionar a la página de inicio después del inicio de sesión exitoso
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });*/
  };

  return (
    <div style={styles.container} className='fullheight'>
      <img
        style={styles.logo}
        src={
          require('../../splash/assets/splash.png')
        }
        alt="Logo"
      />

      <div>
        <CustomTextInput label="Email" value={email} onChangeText={handleEmailChange} inputType="email" />
      </div>
      <div>
        <CustomTextInput label="Password" value={password} onChangeText={handlePasswordChange} secureTextEntry />
      </div>
      <Margin bottom={15} />

      <div style={styles.ButtonContent}>
        <button style={styles.Button} onClick={Submit} disabled={loading}>
          <span style={styles.textBack}>
            {!loading ? 'Login' : 'Loading...'}
          </span>
        </button>
      </div>
      <Margin bottom={15} />

      {loading && <span style={commonStyles.txt_neutral}>Loading...</span>}
    </div>
  );
}

export default Index;
