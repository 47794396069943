import {createAction} from '@reduxjs/toolkit';

export const initialState = {
  isFirstLoad: true,
  isSingin: false,
  introImages: [],
  allmenu: [],
  loadMediaMenu: ''
};

export const isFirstLoad = createAction('root/isFirstLoad');
export const isSingin = createAction('root/isSingin');
export const introImages = createAction('root/introImages');
export const allmenu = createAction('root/allmenu');
export const loadMediaMenu = createAction('root/loadMediaMenu');